<template>
  <div class="outer">
    <span style="white-space: nowrap;">
      <slot />
    </span>

    <LottieWrapper
      name="chevrons"
      :options="{animationData}"
      class="ml-2"
      :style="reverse ? `transform: rotate(180deg);` : ''"
      :height="20"
      :width="150"
    />
  </div>
</template>

<script>
import animationData from '@/assets/lottie/swipe-prompt-animation.json'
import LottieWrapper from '@/components/shared/LottieWrapper'

export default {
  components: { LottieWrapper },

  props: {
    reverse: Boolean,
  },

  data: () => ({
    animationData,
  }),
}
</script>

<style lang="scss" scoped>
.outer {
  display: inline-flex;
  align-items: center;
  padding: 12px 12px 12px 24px;
  width: 100%;
  color: #5A40B8;
  font-size: 14px;
  background: rgba(90, 64, 184, 0.2);
  border: 1px solid #5A40B8;
  border-radius: 6px;
}

@media (min-width: $screen-md-min) {
  .outer {
    width: auto;
  }
}
</style>
