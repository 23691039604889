<template>
  <div class="tw">
    <Layout class="h-screen" main-class="lg:!pt-5" main-style="padding-top: 0.5rem;">
      <Swiper
        :allow-left="page > 0"
        :reverse-in-right="firstInStack"
        :animate-in="page < slidesCount"
        @swipe-left="nextSlide"
        @swipe-right="previousSlide"
        @left-arrow-clicked="previousSlide"
        @right-arrow-clicked="nextSlide"
        @animation-in-end="animationInEnd"
        @animation-out-end="animationOutEnd"
      >
        <template #default="{ rotationStyle }">
          <SwipableCard
            ref="page"
            :style="rotationStyle"
            :reduce-height="$vuetify.breakpoint.mdAndUp ? 70 : 10"
            class="overflow-y-auto"
            width="70vw"
          >
            <div class="fill-height d-flex justify-center align-center">
              <pages :page="page" />
            </div>
          </SwipableCard>
        </template>
        <template #append>
          <div class="w-full text-center pt-5 hidden-md-and-down" :class="{'load-animate': !navigated}">
            <slider-dots
              v-if="page < slidesCount"
              class="mx-auto"
              :step="page"
              :total="slidesCount"
            />
          </div>
        </template>
      </Swiper>
    </Layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Swiper from '~/components/ideaDetail/Swiper'
import SliderDots from '~/components/layout/svgIcons/SliderDots'
import Layout from '~/components/layout/Layout'
import SwipableCard from '~/components/shared/SwipableCard'
import Pages from '~/components/home/Pages'

export default {
  components: {
    Pages,
    SwipableCard,
    Layout,
    Swiper,
    SliderDots,
  },

  data: () => ({
    ideas: null,
    page: 0,
    firstInStack: true,
    slidesCount: 2,
  }),

  async fetch ({ store, redirect, $cookies }) {
    if (store.state.auth.user || $cookies.get('welcome_seen')) {
      return await redirect(302, '/ideas-cards')
    }
  },

  computed: {
    ...mapState({
      navigated: s => s.navigated,
    }),

    ...mapGetters({
      currQueue: 'cards/currQueue',
    }),
  },

  mounted () {
    this.$cookies.set('welcome_seen', 1, { path: '/', expires: new Date(Date.now() + (60 * 60 * 24 * 14 * 1000)) })
  },

  methods: {
    ...mapActions({
      getIdeas: 'cards/getIdeas',
    }),

    nextSlide () {
      this.changeSlide(1)
      this.firstInStack = this.page === 0
    },

    previousSlide () {
      this.changeSlide(-1)
    },

    changeSlide (direction) {
      const index = Math.max(this.page + direction, 0)
      if (index > this.slidesCount - 1) {
        this.$router.push('/ideas-cards')
      }
      this.page = index
    },

    animationInEnd () {
      this.firstInStack = this.page === 0
    },

    animationOutEnd () {
      if (this.firstInStack) {
        this.$notifier.error(
          "Oops, you're already at the beginning -- Please swipe the other way! :)",
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.swipe-header-card {
  border-radius: 10px !important;
}

.swipe-header-icon-close {
  align-self: center;
}

.swipe-header-icon {
  height: 45px;
  width: 50px;
}

.swipe-header {
  width: 100%;
}

#commonHeader {
  background-color: $color-off-white;
}
</style>
