<template>
  <div class="page-inner fill-height fill-width">
    <div v-if="page === 0" class="page-content py-1 pt-sm-6">
      <div class="absolute inset-0 d-none d-sm-flex justify-center align-center overflow-hidden">
        <LottieWrapper name="card1" class="img" :options="{animationData: homeCard1}" />
      </div>

      <div class="pl-sm-12 left-col text-center text-sm-left">
        <h1 class="heading text-h5 text-sm-h4 mb-2 mb-sm-4">
          Become An<br>
          Idea Machine
        </h1>
        <p class="mb-0 mb-sm-8" style="font-size: 16px; font-weight: 350; max-width: 294px;">
          Explore, be inspired and bring ideas to life, in a few minutes each day.
        </p>
        <div class="d-sm-none page-img" style="margin: 0 -2rem;">
          <LottieWrapper name="card1" class="img" :options="{animationData: homeCard1}" />
        </div>
      </div>
      <div class="bottom-btn align-self-end text-body-2">
        <ThreeChevronsBlock :reverse="$vuetify.breakpoint.smAndUp">
          <span class="d-none d-sm-block">Press right arrow to begin</span>
          <span class="d-sm-none">Swipe left to begin</span>
        </ThreeChevronsBlock>
      </div>
    </div>

    <div v-if="page === 1" class="page-content py-1 pt-sm-6">
      <div class="absolute inset-0 d-none d-sm-flex justify-center align-center">
        <LottieWrapper name="card2" class="img" :options="{animationData: homeCard2}" />
      </div>

      <div class="pl-sm-12 left-col text-center text-sm-left">
        <h1 class="heading text-h5 text-sm-h4 mb-2 mb-sm-4">
          Start your<br>
          streak today
        </h1>
        <p class="mb-0 mb-sm-8" style="font-size: 16px; font-weight: 350; max-width: 294px;">
          Flex your innovation muscles, free your bandwidth and log your ideas.
        </p>
        <div class="d-sm-none page-img" style="margin: 0 -2rem;">
          <LottieWrapper name="card2" class="img" :options="{animationData: homeCard2}" />
        </div>
      </div>
      <div class="bottom-btn align-self-end text-body-2">
        <ThreeChevronsBlock :reverse="$vuetify.breakpoint.smAndUp">
          <span class="d-none d-sm-block">Press right arrow to continue</span>
          <span class="d-sm-none">Swipe left to continue</span>
        </ThreeChevronsBlock>
      </div>
    </div>
  </div>
</template>

<script>
import ThreeChevronsBlock from '@/components/home/ThreeChevronsBlock'
import homeCard1 from '@/assets/lottie/home-card-1.json'
import homeCard2 from '@/assets/lottie/home-card-2.json'
import LottieWrapper from '@/components/shared/LottieWrapper'

export default {

  components: {
    LottieWrapper,
    ThreeChevronsBlock,
  },

  props: {
    page: { type: Number, required: true },
  },

  data: () => ({
    homeCard1,
    homeCard2,
  }),
}
</script>

<style lang="scss" scoped>
.page-inner {
  color: #4C4763;
}
.page-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.page-img .img {
  width: 150% !important;
  margin-left: -25% !important;
}

.bottom-btn {
  width: 100%;
}

@media (min-width: $screen-md-min) {
  .page-inner {
    font-size: 1.25rem;
  }
  .col-left {
    max-width: calc(50% - 100px);
  }
  .page-content {
    flex-direction: row;
  }
  .page-img .img {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .bottom-btn {
    width: auto;
  }
}
</style>
