<template>
  <div class="swiper-wrap relative w-full h-full">
    <slot :is-swiping="swipeInProgress || !!xVal" name="background" />
    <div
      v-if="allowLeft && !hideArrows"
      class="left-arrow hidden-sm-and-down pointer-events-auto"
      @click="leftArrowClick"
    >
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32" cy="32" r="31" stroke="#A7A7E7" stroke-width="2" />
        <path
          d="M36.5 19L23 32.5L36.5 46"
          stroke="#A7A7E7"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div
      v-if="allowRight && !hideArrows"
      class="right-arrow hidden-sm-and-down pointer-events-auto"
      @click="rightArrowClick"
    >
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          r="31"
          transform="matrix(-1 0 0 1 32 32)"
          stroke="#A7A7E7"
          stroke-width="2"
        />
        <path
          d="M27.5 19L41 32.5L27.5 46"
          stroke="#A7A7E7"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div class="swipe-parent h-full pointer-events-none">
      <div
        ref="swipe"
        :style="positionStyle"
        class="swipe-container h-full pointer-events-auto"
      >
        <slot :rotation-style="rotationStyle" />
        <slot name="append" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    swipeDisabled: Boolean,
    hideArrows: Boolean,
    allowLeft: { type: Boolean, default: true },
    allowRight: { type: Boolean, default: true },
    reverseInRight: { type: Boolean, default: false },
    reverseInLeft: { type: Boolean, default: false },
    animateIn: { type: Boolean, default: true },
  },

  data () {
    return {
      tapping: false,
      swipeSensitivity: 0,
      minSwipeDistanceBeforeAction: 75,
      swipeInProgress: false,
      xStart: 0,
      yStart: 0,
      xVal: 0,
      yVal: 0,
      xCenter: 0,
      xDistanceToCenter: 0,
      rotationVal: 0,
      animate: false,
      animating: false,
      direction: '',
      animationType: '',
      cardOutRotation: 40,
    }
  },

  computed: {
    offPageWidth () {
      // const swipeContainerWidth = this.$refs.swipe.clientWidth

      return window.outerWidth // + swipeContainerWidth
    },

    cardAnimationSpeed () {
      switch (this.animationType) {
        case 'swipe-in': return 350
        case 'swipe-out': return 250
        case 'swipe-reset': return 250
        case 'slide-in': return 250
        case 'slide-out': return 250
        default: return 500
      }
    },

    easing () {
      switch (this.animationType) {
        case 'swipe-in': return 'ease-out'
        case 'swipe-out': return 'ease-in'
        case 'swipe-reset': return 'ease-out'
        case 'slide-in': return 'ease-out'
        case 'slide-out': return 'ease-in'
        default: return 'ease'
      }
    },

    positionStyle () {
      return {
        transform: `translate3D(${this.xVal}px, ${this.yVal}px, 0px)`,
        transition: this.animate ? `${this.cardAnimationSpeed / 1000}s ${this.easing}` : '',
      }
    },

    rotationStyle () {
      return {
        transform: `rotate(${this.rotationVal}deg)`,
        transformOrigin: '50% 500px',
        transition: this.animate ? `${this.cardAnimationSpeed / 1000}s ${this.easing}` : '',
      }
    },
  },

  mounted () {
    this.setupTouchListener()
    this.setupArrowKeyListener()
  },

  methods: {
    getPos (event) {
      let x
      let y

      if (event.touches) {
        x = event.touches[0].clientX
        y = event.touches[0].clientY
      } else {
        x = event.clientX
        y = event.clientY
      }

      return { x, y }
    },

    setPos (event) {
      if (this.swipeDisabled) {
        return
      }

      const { x } = this.getPos(event)
      if (this.swipeInProgress === false) {
        // We haven't lifted the card yet
        if (Math.abs(this.xStart - x) < this.swipeSensitivity) {
          // AND the card hasn't traveled very car from it's original position
          // so do nothing
          return
        } else {
          // the card HAS traveled far enough now, so officially start the swipe
          this.startSwipe()
        }
      }

      event.preventDefault()
      this.xVal = x - this.xStart
      this.rotationVal = this.getRotation(x)
    },

    getRotation (x) {
      const rotation = (x - this.xStart) / 10
      this.yVal = Math.abs(rotation * 5)
      return rotation > 180 ? 180 : rotation
    },

    setOutRotation (right = false) {
      this.rotationVal = right ? -this.cardOutRotation : this.cardOutRotation
      this.yVal = Math.abs(this.rotationVal * 5)
    },

    startSwipe () {
      this.$emit('swipe-start')
      this.swipeInProgress = true
    },

    endSwipe () {
      this.$emit('swipe-end')
      this.swipeInProgress = false
    },

    enableAnimation () {
      this.animate = true
    },

    disableAnimation () {
      this.animate = false
    },

    waitNextTick () {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve()
        })
      })
    },

    waitUpdate () {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 50)
      })
    },

    waitAnimation () {
      this.animating = true
      return new Promise((resolve) => {
        setTimeout(() => {
          this.animating = false
          resolve()
        }, this.cardAnimationSpeed)
      })
    },

    async resetSwipePosition () {
      if (this.animateIn) {
        this.enableAnimation()
        this.xVal = 0
        this.yVal = 0
        this.rotationVal = 0
        await this.waitAnimation()
        this.disableAnimation()
      }
      this.enableSideScroll()
      this.$emit('animation-in-end')
    },

    async setSwipeLeftCardPos (args = {}) {
      // If we don't disable animation
      // Then the card will look like it's zooming
      // across the page

      this.disableAnimation()
      this.xVal = this.reverseInLeft ? -this.offPageWidth : this.offPageWidth
      this.animationType = 'slide-in'
      if (! args.noRotation) {
        this.animationType = 'swipe-in'
        this.setOutRotation(this.reverseInLeft)
      }
      await this.waitUpdate()
      this.$emit('animation-out-end', 'right')
      await this.resetSwipePosition()
    },

    async setSwipeRightCardPos (args = {}) {
      this.disableAnimation()
      this.xVal = this.reverseInRight ? this.offPageWidth : -this.offPageWidth
      this.animationType = 'slide-in'
      if (! args.noRotation) {
        this.animationType = 'swipe-in'
        this.setOutRotation(! this.reverseInRight)
      }
      await this.waitUpdate()
      this.$emit('animation-out-end', 'left')
      await this.resetSwipePosition()
    },

    fingerDown (event) {
      this.tapping = true
      const { x, y } = this.getPos(event)
      this.xStart = x
      this.yStart = y
      this.xCenter = event.srcElement.clientWidth / 2
      this.xDistanceToCenter = x - this.xCenter
      this.setPos(event)
    },

    async fingerUp () {
      this.tapping = false
      this.endSwipe()
      if (this.xVal) {
        // Disable side scrolling otherwise swiping during the animation
        // will create weird behavior.
        this.disableSideScroll()
        this.enableAnimation()
        if (this.xVal > this.minSwipeDistanceBeforeAction) {
          this.xVal = this.offPageWidth
          this.setOutRotation()
          this.animationType = 'swipe-out'
          await this.waitAnimation()
          if (this.allowLeft) {
            this.$emit('swipe-right')
          }
          await this.setSwipeRightCardPos()
        } else if (this.xVal < -this.minSwipeDistanceBeforeAction) {
          this.xVal = -this.offPageWidth
          this.setOutRotation(true)
          this.animationType = 'swipe-out'
          await this.waitAnimation()
          if (this.allowRight) {
            this.$emit('swipe-left')
          }
          await this.setSwipeLeftCardPos()
        } else {
          this.animationType = 'swipe-reset'
          await this.resetSwipePosition()
        }
      }
    },

    disableSideScroll () {
      document.querySelector('body').style.overflowX = 'hidden'
    },

    enableSideScroll () {
      document.querySelector('body').style.overflowX = ''
    },

    fingerMove (event) {
      if (! this.tapping) {
        this.fingerDown(event)
      } else {
        this.setPos(event)
      }
    },

    setupArrowKeyListener () {
      window.onkeydown = (event) => {
        if (event.key === 'ArrowLeft') {
          this.leftArrowClick()
        } else if (event.key === 'ArrowRight') {
          this.rightArrowClick()
        }
      }
    },

    setupTouchListener () {
      this.$refs.swipe.addEventListener('touchend', this.fingerUp)
      this.$refs.swipe.addEventListener('touchmove', this.fingerMove)
    },

    async leftArrowClick (emit = true) {
      if (this.swipeDisabled || this.animating) {
        return
      }
      this.enableAnimation()
      this.xVal = this.offPageWidth
      this.animationType = 'slide-out'
      await this.waitAnimation()
      if (emit) { this.$emit('left-arrow-clicked') }
      await this.setSwipeRightCardPos({ noRotation: true })
    },

    async rightArrowClick (emit = true) {
      if (this.swipeDisabled || this.animating) {
        return
      }
      this.enableAnimation()
      this.xVal = -this.offPageWidth
      this.animationType = 'slide-out'
      await this.waitAnimation()
      if (emit) { this.$emit('right-arrow-clicked') }
      await this.setSwipeLeftCardPos({ noRotation: true })
    },
  },
}
</script>

<style scoped lang="scss">
.swiper-wrap {
  z-index: 1;
  &.expanded {
    z-index: 10;
  }
}

@media (max-width: $screen-sm-max) {
  .swipe-parent,
  .swipe-container {
    height: 100%;
  }
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 10;
  cursor: pointer;
  img {
    width: 50px;
  }
  circle,
  path {
    transition: all 0.3s ease;
  }
  &:hover {
    circle {
      stroke: #5a41ba;
      fill: #e8e8f4;
    }
    path {
      stroke: #5a41ba;
    }
  }
}

.left-arrow {
  left: 4vw;
}

.right-arrow {
  right: 4vw;
}
</style>
